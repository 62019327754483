<template>
  <div>
    <v-data-table
      v-if="headers && data"
      :headers="headers"
      :items="data"
      item-key="ID"
      elevation="0"
      light
      :locale="locale"
      hide-default-footer
    >
      <template v-slot:top>
        <v-layout wrap>
          <v-flex xs12
            v-for="e of item.ExpandDataValues"
            :key="e.key"
            style="padding: 5px 10px;"
          >
            <b>{{e.key}} :</b> {{e.value}}
          </v-flex>
        </v-layout>
        <v-toolbar flat>
            <v-spacer />
            <v-checkbox v-model="showAll" :label="$t('common.showAll', locale)" @change="handleGetData" style="margin: 20px 10px 0 0;"/>
          <v-btn
            v-if="perms && perms.indexOf('a5c7ba9b-a5c1-4338-9235-c8a6c842882b') >= 0"
            @click="handlePDF(item)"
            color="primary"
            style="margin-left: 10px;"
            small
          >
            <v-icon small>mdi-file-pdf-box</v-icon>
          </v-btn>
        </v-toolbar>
        <v-toolbar flat v-if="editedItem">
          <span style="padding: 0 20px;">
            Entrada:
          </span>
          <signature
            style="margin: 0 20px;"
            locale="es"
            :isNew="editedItem.signatureIni ? false : true"
            :onSave="handleCheckin"
            :disabled="!editedItem.signatureIni ? false : true"
          />
          <span style="padding: 0 20px;">
            Salida:
          </span>
          <signature
            style="margin-left: 20px;"
            locale="es"
            :isNew="editedItem.signatureEnd ? false : true"
            :onSave="handleCheckout"
            :disabled="editedItem.signatureIni && !editedItem.signatureEnd ? false : true"
          />
          <v-spacer />
            <v-btn
              @click="editedItem = null"
              small
            >
              {{ $t('common.cancel', locale) }}
            </v-btn>
        </v-toolbar>
        <v-toolbar flat v-else>
          <v-spacer />
            <v-btn
              @click="handleAdd"
              color="primary"
              dark
              small
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.timeIni="{ item }">
        <small v-if="item.timeIni">
          {{item.date}}<br />
        </small>
        {{ getTimeFormated(item.timeIni) }}
      </template>
      <template v-slot:item.timeEnd="{ item }">
        <small v-if="item.timeEnd">
          {{item.date}}<br />
        </small>
        {{ getTimeFormated(item.timeEnd) }}
      </template>
      <template v-slot:item.signatureIni="{ item }">
        <img 
          v-if="item.signatureIni"
          :src="item.signatureIni"
          style="width: 100px;border: 1px solid #cccccc80;"
        />
      </template>
      <template v-slot:item.signatureEnd="{ item }">
        <img 
          v-if="item.signatureEnd"
          :src="item.signatureEnd"
          style="width: 100px;border: 1px solid #cccccc80;"
        />
      </template>
      <template v-slot:item.action="{ item }">
        <div>
          <v-spacer />
      <v-layout wrap>
        <v-flex xs6 
        >
      <v-btn
          v-if="perms && perms.indexOf('512c8864-52ca-445d-8c31-2a2e45132942') >= 0"
          small
      >
        <delete-item
          :id="item.id"
          :onDeleteItem="handleDeleteCheckin"
          :locale="locale"
          icon="true"
        />
      </v-btn>
        </v-flex>
        <v-flex xs6>
        <v-btn
          @click="handleEdit(item)"
          color="primary"
          style="margin-left: 10px;"
          small
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        </v-flex>
      </v-layout>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import Signature from '@/components/signature/Index'
import DeleteItem from '@/components/DeleteItem'
export default {
  components: {
    Signature,
    DeleteItem,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
    perms: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    editedItem: null,
    headers: null,
    data: null,
    showAll: false,
    /*
    data: [
      {
        id: '1.1',
        timeIni: 112,
        timeEnd: null,
        signatureEnd: null,
        arriveOnly: true,
        goAlone: false,
      },
      {
        id: '1.2',
        timeIni: 1120,
        signatureIni: null,
        timeEnd: 1140,
        signatureEnd: null,
        arriveOnly: true,
        goAlone: false,
      },
    ],
    */
    //showSignatureForm: false,
    signatureData: null,
  }),
  computed: {
    attendanceID () {
      return this.item.ID
    },
  },
  watch: {
    'item.ID' () {
      this.editedItem = null
      this.showAll = false
      this.data = []
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
    this.prepareHeaders()
  },
  methods: {
    getKeyValue (v) {
      const result = []
      if (v) {
        for (let [key, value] of Object.entries(v)) {
          result.push({
            key, value
          })
        }
      }
      return result
    },
    handleGetData () {
      api.getAllWithoutLimit ('booking', `v1/private/attendance/${this.attendanceID}/checkin`, false, `&states=${this.showAll ? 'all' : ''}`)
        .then(response => {
          if (!response) return
          this.data = response.map(item => {
            return {
              id: item.ID,
              attendanceID: item.AttendanceID,
              timeIni: item.TimeIni,
              timeEnd: item.TimeEnd,
              signatureIni: item.SignatureIni,
              signatureEnd: item.SignatureEnd,
              date: item.Date
            }
          })

          if (this.data.length === 0) this.handleAdd()
        })
    },
    handleDeleteCheckin (v) {
      api.deleteItem ('booking', `v1/private/attendance/`, v.concat('/checkin'))
        .then(() => {
          this.handleGetData()
        })
    },
    prepareHeaders () {
      this.headers = [
        { text: 'Hora entrada', value: 'timeIni', sortable: false },
        { text: 'Firma entrada', value: 'signatureIni', sortable: false },
        { text: 'Hora salida', value: 'timeEnd', sortable: false },
        { text: 'Firma salida', value: 'signatureEnd', sortable: false },
        { value: 'action', sortable: false, align: 'end' },
      ]
    },
    getTimeFormated (v) {
      return v ? utils.convertTime(v) : ''
    },
    getTimeInMinutes () {
      const now = new Date()
      return now.getHours() * 60 + now.getMinutes()
    },
    handleCheckin (v) {
      this.editedItem.timeIni = this.getTimeInMinutes()
      this.editedItem.signatureIni = v
      this.handleSave()
    },
    handleCheckout (v) {
      this.editedItem.timeEnd = this.getTimeInMinutes()
      this.editedItem.signatureEnd = v
      this.handleSave()
    },
    handleSave () {
      api.put('booking', `v1/private/attendance/${this.attendanceID}/checkin`, this.editedItem)
        .then(() => {
          this.editedItem = null
          this.handleGetData()
        } )
    },
    handleAdd () {
      this.handleEdit({
        id: utils.getNewID(),
        attendanceID: this.attendanceID,
        timeIni: null,
        signatureIni: null,
        timeEnd: null,
        signatureEnd: null,
      })
    },
    handleEdit (v) {
      this.editedItem = JSON.parse(JSON.stringify(v))
    },
    handlePDF () {
      api.getAllWithoutLimit ('booking', `v1/private/attendance/${this.attendanceID}/pdf`, false, `&states=${this.showAll ? 'all' : ''}`)
        .then(response => {
          window.open(response, '_blank')
        })
    },
  },
}
</script>

